<template>
  <div class="store-homepage-box">
    <SmallHeader :basic-information="basicInformation" />
    <ShopTabs tab-active-path="/storeManagement/storeHomepage" @changeShopPath="changeShopPath" />
    <!-- 有店铺的时候显示开始 -->
    <div v-if="isShowShop" class="store-box">
      <!-- 商铺banner -->
      <div class="store-banner-box">
        <el-carousel trigger="click" height="460px" :interval="4000">
          <el-carousel-item
            v-for="(item, index) in tradeShopBannerList"
            :key="index"
          >
            <!-- <div v-if="item.flag==='01'"> -->
            <img
              v-if="item.bannerImgUrl"
              class="banner-image"
              :src="item.bannerImgUrl.indexOf('http')>=0?item.bannerImgUrl:($fileUrl + item.bannerImgUrl)"
              alt="banner"
              @click="skip(item)"
            >
            <!-- 这个是预设的图片 -->
            <img
              v-else
              class="banner-image"
              src="@/assets/storeManagement/image/default_banner.jpg"
              alt="banner"
            >
            <!-- </div> -->
          </el-carousel-item>
          <!-- 这个是默认的图片 -->
          <el-carousel-item v-if="tradeShopBannerList.length === 0">
            <img
              class="banner-image"
              src="@/assets/storeManagement/image/default_banner.jpg"
              alt="banner"
            >
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- 资源推荐 -->
      <div
        v-if="tradeShopRecommendProductList.length > 0"
        class="resource-recommendation-box"
      >
        <h3 class="title">
          资源推荐
        </h3>
        <div class="recommend-products-list">
          <div class="left-button" @click="rotate(1)">
            <i class="el-icon-arrow-left" />
          </div>
          <div class="right-button" @click="rotate(2)">
            <i class="el-icon-arrow-right" />
          </div>
          <el-carousel
            ref="carousel"
            :interval="4000"
            type="card"
            height="320px"
            indicator-position="none"
          >
            <el-carousel-item
              v-for="(item, index) in tradeShopRecommendProductList"
              :key="index"
            >
              <router-link
                class="products-box"
                :to="{
                  path: '/spotHall/spotHallDetail',
                  query: { shopId: basicInformation.id, id: item.tradeGoodsListingId },
                }"
              >
                <img
                  class="product-image"
                  :src="item.productUrl.indexOf('http')>=0?item.productUrl:($fileUrl + item.productUrl)"
                  alt="产品图片"
                >
                <div class="recommend-product-content">
                  <div>
                    <p class="specification elp-1">
                      {{ item.goodsTwoCategoryName }}
                      {{ item.goodsMaterialName }} {{ item.goodsSpecName }}
                    </p>
                    <p class="inventory-box elp-1">
                      挂牌库存{{ item.availableListingWeight||0 }}吨&nbsp;&nbsp;|&nbsp;&nbsp;起订量{{ item.minOrderQuantity ||0 }}吨
                    </p>
                    <p class="city elp-1">
                      {{ item.placeProvince }}{{ item.placeCity }}{{ item.placeTown }}{{ item.placeDetailAddr }}-{{ item.warehouseName }}
                    </p>
                    <div class="tag-box">
                      <span>可开票</span>
                      <span>全款购买</span>
                      <span>保证金预付</span>
                    </div>
                  </div>
                  <span
                    class="price"
                  >¥{{ item.unitPriceIncludingTax || 0 }}/吨</span>
                </div>
              </router-link>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <!-- 供应产品  店铺商品列表 -->
      <div class="supply-product-box">
        <!-- 供应产品 -->
        <div class="title">
          <h3>供应产品</h3>
          <router-link
            :to="{
              path: '/storeManagement/ourStoreGoods',
              query: {
                shopId: basicInformation.id,
                cmpCode: basicInformation.cmpCode,
              },
            }"
          >
            查看更多<i class="el-icon-arrow-right" />
          </router-link>
        </div>
        <StoreMerchandiseList :page-type="true" :cmp-code="basicInformation.cmpCode" />
      </div>
      <!-- 关于我们 -->
      <div class="about-us-box">
        <div class="title">
          <h3>关于我们</h3>
          <div class="tab-box">
            <span
              :class="[tabState === 1 ? 'active' : '']"
              @click="tabState = 1"
            ><i class="icon el-icon-office-building" />公司信息</span>
            <span
              :class="[tabState === 2 ? 'active' : '']"
              @click="tabState = 2"
            ><i class="icon el-icon-map-location" />公司地点</span>
          </div>
        </div>
        <div v-if="tabState === 1" class="corporate-information-box">
          <!-- 公司图片跟公司简介 -->
          <div class="image-and-name">
            <img
              v-if="basicInformation.cmpRealPhotoUrl"
              class="company-image"
              :src="$fileUrl + basicInformation.cmpRealPhotoUrl"
              alt="公司相片"
            >
            <img
              v-else
              class="company-image"
              src="@/assets/storeManagement/image/cmp_img.png"
              alt="公司相片"
            >
            <div class="name">
              <h3 class="elp-1">
                {{ basicInformation.cmpName }}
              </h3>
              <p class="content">
                {{ basicInformation.cmpProfile }}
              </p>
            </div>
          </div>
          <!-- 公司的一些情况 -->
          <div class="introductory-box">
            <div class="flex-box">
              <div class="item">
                <span>注册资本</span>
                <p>{{ basicInformation.cmpRegFund || 0 }}万元</p>
              </div>
              <div class="item">
                <span>成立时间</span>
                <p>{{ basicInformation.cmpDate }}</p>
              </div>
              <div class="item">
                <span>营业期限</span>
                <p>{{ basicInformation.cmpCloseDate }}</p>
              </div>
              <div class="item">
                <span>统一社会信用代码</span>
                <p>{{ basicInformation.cmpUnicode }}</p>
              </div>
              <div class="item">
                <span>登记机关</span>
                <p>{{ basicInformation.cmpRegOrg }}</p>
              </div>
            </div>
            <div class="flex-box interval">
              <div class="item">
                <span>注册地址</span>
                <p>
                  {{ basicInformation.cmpAddress }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="tabState === 2" class="map-box">
          <Map v-if="tabState === 2" v-bind="basicInformation" :cmp-address="cmpAddress" />
        </div>
      </div>
    </div>
    <!-- 有店铺的时候显示结束 -->
    <!-- 没有店铺的时候开始 -->
    <div v-else class="no-shop-box">
      <img src="@/assets/storeManagement/image/no_shop.png" alt="没有商铺">
      <p>
        抱歉，该店铺主页暂时无法查看<router-link :to="{ path: '/' }">
          返回首页
        </router-link>
      </p>
    </div>
    <!-- 没有店铺的时候结束 -->
    <!-- 当预览的时候一个遮罩层遮住不可点击 -->
    <div v-if="showMask" class="mask-layer-box" />
  </div>
</template>

<script>
import SmallHeader from '../components/SmallHeader.vue'
import Map from '../components/Map.vue'
import StoreMerchandiseList from '../components/StoreMerchandiseList.vue'
import ShopTabs from '@/components/ShopTabs.vue'
import {
  queryShopConfigInfoDesc,
  queryShopBaseInfoDesc
} from '@/http/storeManagement'
export default {
  components: { SmallHeader, Map, StoreMerchandiseList, ShopTabs },
  data() {
    return {
      total: 0,
      tabState: 1,
      isShowShop: false,
      showMask: false,
      tradeShopRecommendProductList: [], // 资源推荐
      tradeShopBannerList: [], // banne图片
      basicInformation: {}, // 基础信息
      factoryId: '' // 加工服务所用id
    }
  },
  mounted() {
    // 当预览过来的时候  就要把这些信息赋值上去了
    if (this.$route.query.previewInformation) {
      this.isShowShop = true
      this.showMask = true
      const previewInformation = JSON.parse(decodeURIComponent(this.$route.query.previewInformation))
      // banner图片
      if (previewInformation.shopBannerList) { this.tradeShopBannerList = [...previewInformation.shopBannerList] }
      // 推荐资源
      if (previewInformation.tradeShopRecommendProductList) {
        this.tradeShopRecommendProductList = [
          ...previewInformation.tradeShopRecommendProductList
        ]
      }
      this.init(previewInformation.shopId)
      // 供应产品
      if (previewInformation.respGoodsHallList) { this.respGoodsHallList = [...previewInformation.respGoodsHallList] }
      return
    }
    // 请求接口
    this.init(this.$route.query.shopId, true)
  },
  methods: {
    // 初始化店铺信息的函数啊
    init(shopId, type) {
      queryShopBaseInfoDesc(shopId || '', (res) => {
        if (res.data) this.basicInformation = { ...res.data }
        // 只有在有数据的时候才会出现
        this.isShowShop = res.data.state !== '04'
        this.cmpAddress = `${res.data.placeProvinceName}${res.data.placeCityName}${res.data.placeRegionName}${res.data.placeDesc}`
      })
      if (type) {
        queryShopConfigInfoDesc(shopId || '', (res) => {
          if (res.data) {
            // banner图片
            if (res.data.tradeShopBannerList) { this.tradeShopBannerList = [...res.data.tradeShopBannerList] }
            // 推荐资源
            if (res.data.tradeShopRecommendProductList) {
              this.tradeShopRecommendProductList = [
                ...res.data.tradeShopRecommendProductList
              ]
            }
            // 供应产品
            if (res.data.respGoodsHallList) { this.respGoodsHallList = [...res.data.respGoodsHallList] }
            this.factoryId = res.data.factoryId
          }
        })
      }
    },
    // 点击banner跳转
    skip(item) {
      if (item.linkType === '02' && item.linkUrl)window.open(item.linkUrl)
    },
    // 控制轮播图滚动
    rotate(index) {
      index === 2 ? this.$refs.carousel.prev() : this.$refs.carousel.next()
    },
    // 切换
    changeShopPath(path) {
      this.$router.push({
        path,
        query: {
          id: this.factoryId,
          shopId: this.basicInformation.id,
          cmpCode: this.basicInformation.cmpCode
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.store-homepage-box {
  width: 100%;
  background-color: #fff;
  .mask-layer-box{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
  }
  .store-box {
    padding-bottom: 80px;
    border-bottom: 1px solid #e7e7e7;
  }
  .no-shop-box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 100px;
    p {
      color: #000000;
      font-size: 24px;
      font-weight: 400;
      a {
        color: #d31e0e;
        text-decoration: underline;
      }
    }
    img {
      width: 567px;
      height: 328px;
    }
  }
  .about-us-box {
    width: $centerPlateWidth;
    margin: auto;
    .map-box {
      height: 404px;
      width: 100%;
    }
    .corporate-information-box {
      .introductory-box {
        padding: 24px 32px;
        background-color: #f9f9f9;
        .flex-box {
          display: flex;
          // align-items: center;
          justify-content: space-between;
          .item {
            flex: 1;
            display: flex;
            flex-direction: column;
            color: #999999;
            font-size: 14px;
            font-weight: 400;
            p {
              margin-top: 8px;
              color: #000000;
            }
          }
        }
        .interval {
          margin-top: 16px;
        }
      }
      .image-and-name {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        .name {
          flex: 1;
          height: 364px;
          padding-left: 40px;
          background-color: #f9f9f9;
          .content {
            max-height: 288px;
            color: #333333;
            font-size: 14px;
            font-weight: 400;
            line-height: 1.6;
            overflow-y: auto;
            padding-right: 32px;
            margin-right: 8px;
          }

          // 滚动条
          .content::-webkit-scrollbar {
            width: 4px;
            height: 4px;
            background-color: #f9f9f9;
          }

          .content::-webkit-scrollbar-track {
            // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
            background-color: #f5f5f5;
          }

          .content::-webkit-scrollbar-thumb {
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
            background-color: #d9d9d9;
            border-radius: 10px;
          }
          h3 {
            color: #000000;
            font-size: 20px;
            font-weight: 500;
            margin: 32px 0 16px;
          }
        }
        .company-image {
          height: 404px;
          width: 540px;
        }
      }
    }
    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 64px 0 32px;
      .tab-box {
        color: #333333;
        font-size: 16px;
        font-weight: 500;
        display: flex;
        span {
          display: flex;
          align-items: center;
          margin-left: 24px;
          cursor: pointer;
          .icon {
            font-size: 24px;
            color: #333333;
          }
        }
        .active {
          color: #d31e0e;
          .icon {
            color: #d31e0e;
          }
        }
      }
      h3 {
        color: #000000;
        font-size: 28px;
        font-weight: 500;
      }
    }
  }
  .supply-product-box {
    width: $centerPlateWidth;
    margin: auto;
    .list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 80px;
      padding: 16px 24px;
      .view-details-button {
        display: inline-block;
        width: 103px;
        height: 38px;
        border: 1px solid #e7e7e7;
        background-color: #fff;
        text-align: center;
        line-height: 36px;
        color: #d31e0e;
        font-size: 16px;
        font-weight: 400;
        transition: all 0.5s;
      }
      .view-details-button:hover {
        color: #fff;
        background-color: #d31e0e;
        border-color: #d31e0e;
      }
      .price-or-weight {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        flex: 0 0 144px;
        p {
          color: #d31e0e;
          font-size: 22px;
          font-weight: 500;
        }
        span {
          color: #999999;
          font-size: 14px;
          font-weight: 400;
          margin-top: 4px;
        }
      }
      .model {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        flex: 0 0 220px;
        p {
          color: #333333;
          font-size: 16px;
          font-weight: 400;
        }
        span {
          margin-top: 8px;
          color: #666666;
          font-size: 14px;
          font-weight: 400;
        }
      }
      .product-content {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;
        height: 80px;
        flex: 0 0 296px;
        .contact-information {
          display: flex;
          align-items: center;
          background-color: #fff;
          height: 20px;
          color: #f89705;
          font-size: 14px;
          background-color: rgb(253, 246, 239);
          cursor: pointer;
          img {
            height: 16px;
            width: 16px;
          }
        }
        .description {
          display: inline-block;
          background-color: rgb(251, 237, 236);
          color: #d31e0e;
          font-size: 14px;
          padding: 0 8px;
          height: 24px;
          line-height: 24px;
        }
        .name {
          color: #333333;
          font-size: 16px;
          font-weight: 500;
        }
      }
      .product-image {
        flex: 0 0 80px;
        max-width: 80px;
        height: 80px;
        margin-right: 16px;
      }
    }
    .product-list {
      margin-top: 16px;
      border: 1px solid #e7e7e7;
      border-radius: 2px;
      .sort-header {
        display: flex;
        height: 44px;
        background-color: #f9f9f9;
        border-bottom: 1px solid #e7e7e7;
        box-sizing: border-box;
        .item {
          display: flex;
          height: 100%;
          align-items: center;
          justify-content: center;
          padding: 0 16px;
          color: #666666;
          font-size: 14px;
          cursor: pointer;
          .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            i {
              font-size: 12px;
              margin-left: 4px;
              color: #d5d5d5;
            }
            i:first-of-type {
              transform: translateY(2px);
            }
            i:last-of-type {
              transform: translateY(-2px);
            }
            .active {
              color: #999999;
            }
          }
        }
        .active {
          position: relative;
          color: #d31e0e;
          background-color: #fff;
        }
        .active:after {
          content: " ";
          position: absolute;
          bottom: 0;
          left: 14px;
          width: calc(100% - 28px);
          background-color: #d31e0e;
          height: 2px;
        }
      }
    }
    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 60px 0 8px;
      h3 {
        color: #000000;
        font-size: 28px;
        font-weight: 500;
      }
      a {
        color: #666666;
        font-size: 14px;
        font-weight: 400;
        cursor: pointer;
        i {
          color: #666;
          font-size: 14px;
        }
      }
    }
  }
  .resource-recommendation-box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 518px;
    background: url(../../../assets/storeManagement/image/banner.png) no-repeat;
    background-size: 100% 100%;
    .recommend-products-list {
      position: relative;
      height: 320px;
      width: $centerPlateWidth;
      .left-button,
      .right-button {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        display: none;
        height: 64px;
        width: 64px;
        align-items: center;
        justify-content: center;
        border-radius: 32px;
        background-color: #fff;
        cursor: pointer;
        i {
          font-size: 32px;
          color: #999999;
        }
      }
      .left-button {
        left: -102px;
      }
      .right-button {
        right: -102px;
      }
      .products-box {
        display: flex;
        align-items: center;
        padding: 32px;
        background-color: #fff;
        box-shadow: 0 8px 30px 0 rgba(0, 0, 0, 0.17),
          0 16px 24px 0 rgba(0, 0, 0, 0.03);
        .recommend-product-content {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          flex: 1;
          height: 256px;
          .tag-box{
            display: flex;
            align-items: center;
            margin-top: 14px;
            span{
              margin-right: 8px;
              display: inline-block;
              height: 24px;
              line-height: 24px;
              padding: 0 8px;
              color: #f89705;
              font-size: 14px;
              font-weight: 400;
              background-color: rgb(254,247,234);
            }
          }
          .inventory-box{
            color: #666666;
            font-size: 16px;
            font-weight: 400;
            margin: 12px 0;
          }
          .city{
            color: #666666;
            font-size: 16px;
            font-weight: 400;
          }
          .specification {
            color: #333333;
            font-size: 24px;
            font-weight: 500;
          }
          .price {
            color: #d31e0e;
            font-size: 32px;
            font-weight: 500;
          }
        }
        .product-image {
          height: 256px;
          width: 256px;
          margin-right: 32px;
        }
      }
      /deep/.el-carousel__arrow {
        display: none;
      }
      .is-active {
        width: 60%;
        transform: translateX(240px) scale(1) !important;
      }
    }
    .title {
      color: #000000;
      font-size: 28px;
      font-weight: 500;
      margin-bottom: 32px;
    }
  }
  .resource-recommendation-box:hover{
    .left-button, .right-button{
      display: flex;

      }
  }
  .store-banner-box {
    height: 460px;
    width: 100%;
    /deep/.el-carousel__button {
      height: 12px;
      width: 12px;
      border-radius: 50%;
    }
    .banner-image {
      height: 100%;
      width: 100%;
    }
  }
}
</style>
